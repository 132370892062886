import './header.css';
import Menu from "./Menu";
import Logo from "./Logo";
import Contacts from "./Contacts";
import Order from "./Order";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../index";
import {isMobile} from 'react-device-detect';

const Header = () => {
    const [content, setContent] = useState([])

    useEffect(() => {
        getContent()
    }, [])

    const getContent = (data) => {
        axios.get(API_URL).then(data => setContent(data.data))
    }

    const resetState = () => {
        getContent();
    };

    if (isMobile) {
        return (
            <div className="menu mob">
                <Menu/>
                <Logo/>
                <Order/>
            </div>
        )
    }
    return (
        <div className="menu">
            <Menu/>
            <Logo/>
            <Contacts content={content} resetState={resetState}/>
        </div>
    )
}

export default Header;