import './App.css';
import {Fragment, useEffect, useState} from "react";
import Header from "./appHeader/Header";
import MenuList from "./appHeader/MenuList";
import ContactList from "./appHeader/ContactList";
import Home from "./appHome/Home";
import Footer from "./appFooter/Footer";
import axios from "axios";
import {API_URL} from "./index";

function App() {
    const [content, setContent] = useState([])

    useEffect(() => {
        getContent()
    }, [])

    const getContent = (data) => {
        axios.get(API_URL).then(data => setContent(data.data))
    }

    const resetState = () => {
        getContent();
    };

    return (
        <Fragment>
            <Header/>
            <MenuList/>
            <ContactList content={content} resetState={resetState} />
            <Home/>
            <Footer data={content} resetState={resetState} />
        </Fragment>
    );
}

export default App;
