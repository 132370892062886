// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  background-color: #1C1C1C;
  padding: 16px 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 300px);
  height: fit-content;
  z-index: 4;
}

.mob {
  padding: 32px 30px 16px 30px;
  width: calc(100% - 60px);
  align-content: center;
  z-index: 4;
}

`, "",{"version":3,"sources":["webpack://./src/appHeader/header.scss","webpack://./src/appHeader/header.css"],"names":[],"mappings":"AAOA;EACE,yBANO;EAOP,mBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;ACLF;;ADQA;EACE,4BAAA;EACA,wBAAA;EACA,qBAAA;EACA,UAAA;ACLF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n\n$nav-bg: #1C1C1C;\n$safe: 150px;\n$safe-mob: 30px;\n$p16: 16px;\n\n.menu {\n  background-color: $nav-bg;\n  padding: $p16 $safe;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  justify-content: space-between;\n  width: calc(100% - $safe * 2);\n  height: fit-content;\n  z-index: 4;\n}\n\n.mob {\n  padding: calc($p16 * 2) $safe-mob $p16 $safe-mob;\n  width: calc(100% - $safe-mob * 2);\n  align-content: center;\n  z-index: 4;\n}\n","@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n.menu {\n  background-color: #1C1C1C;\n  padding: 16px 150px;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  justify-content: space-between;\n  width: calc(100% - 300px);\n  height: fit-content;\n  z-index: 4;\n}\n\n.mob {\n  padding: 32px 30px 16px 30px;\n  width: calc(100% - 60px);\n  align-content: center;\n  z-index: 4;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
