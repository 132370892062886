import './logo.css';
import {HandySvg} from 'handy-svg';
import {isMobile} from 'react-device-detect';


export const LogoIcon = () => (
    <HandySvg
        src={"icons/logo.svg"}
        className="icon"
        width="40"
        height="40"
    />
);


const Logo = () => {
    if (isMobile) {
        return (
            <div className="logo_frame_mob">

                <div className="logo_itself_mob">
                    <LogoIcon/>
                </div>

                <div className="logo_text_mob">
                    <h5>SAIN</h5>
                </div>
            </div>
        )
    }
    return (
        <div className="logo_frame">

            <div className="logo_itself">
                <LogoIcon/>
            </div>

            <div className="logo_text">
                <h1>SAIN</h1>
            </div>
        </div>
    )
}

export default Logo;