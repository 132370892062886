import axios from "axios";
import {useEffect, useState} from "react";
import {API_URL} from "../index";
import CategoryBlock from "../appCategoryBlock/CategoryBlock";
import './home.css';
import {isMobile} from "react-device-detect";


const Home = () => {
    const [content, setContent] = useState([])

    useEffect(() => {
        getContent()
    }, [])

    const getContent = (data) => {
        axios.get(API_URL).then(data => setContent(data.data))
    }

    const resetState = () => {
        getContent();
    };

    if (isMobile) {
        return (
            <div className={"main_mob"}>
                <CategoryBlock content={content} resetState={resetState} type={false}/>
                <CategoryBlock content={content} resetState={resetState} type={true}/>
            </div>
        )
    }
    return (
        <div className={"main"}>
            <CategoryBlock content={content} resetState={resetState} type={false}/>
            <CategoryBlock content={content} resetState={resetState} type={true}/>
        </div>
    )
}

export default Home;