// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: start;
}
.order h5 {
  margin: 0 auto 0 auto;
}
.order .toggle {
  display: block;
}
.order .toggle svg {
  transform: scale(1);
  animation: transformer 0.5s ease 0s 1;
}
@keyframes transformer {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

`, "",{"version":3,"sources":["webpack://./src/appHeader/order.scss","webpack://./src/appHeader/order.css"],"names":[],"mappings":"AAOA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,oBAAA;ACLF;ADOE;EACE,qBAAA;ACLJ;ADQE;EACE,cAAA;ACNJ;ADOI;EAEE,mBAAA;EAEA,qCAAA;ACLN;ADeI;EACE;IACE,mBAAA;ECLN;EDOI;IACE,mBAAA;ECLN;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n\n$nav-bg: #1C1C1C;\n$safe: 150px;\n$p16: 16px;\n\n\n.order {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-content: start;\n\n  h5 {\n    margin: 0 auto 0 auto;\n  }\n\n  .toggle {\n    display: block;\n    svg {\n      -webkit-transform: scale(1);\n      transform: scale(1);\n      -webkit-animation: transformer .5s ease 0s 1;\n      animation: transformer .5s ease 0s 1;\n    }\n    @-webkit-keyframes transformer {\n      from {\n        -webkit-transform: scale(0);\n      }\n      to {\n        -webkit-transform: scale(1);\n      }\n    }\n    @keyframes transformer {\n      from {\n        transform: scale(0);\n      }\n      to {\n        transform: scale(1);\n      }\n    }\n  }\n}","@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n.order {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-content: start;\n}\n.order h5 {\n  margin: 0 auto 0 auto;\n}\n.order .toggle {\n  display: block;\n}\n.order .toggle svg {\n  -webkit-transform: scale(1);\n  transform: scale(1);\n  -webkit-animation: transformer 0.5s ease 0s 1;\n  animation: transformer 0.5s ease 0s 1;\n}\n@-webkit-keyframes transformer {\n  from {\n    -webkit-transform: scale(0);\n  }\n  to {\n    -webkit-transform: scale(1);\n  }\n}\n@keyframes transformer {\n  from {\n    transform: scale(0);\n  }\n  to {\n    transform: scale(1);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
