import './order.css';
import {HandySvg} from 'handy-svg';
import {isMobile} from "react-device-detect";
import {MenuNonIcon} from "./Menu";


export const IconOrder = () => (
    <HandySvg
        src={"icons/order.svg"}
        className="icon"
        width="40"
        height="40"
    />
);


const Order = () => {
    if (isMobile) {
        return (
            <button className="order" type="button" onClick={() => {
                if (document.getElementsByClassName("yesorder").length != 0) {
                    document.getElementsByClassName("yesorder")[0].classList.toggle("toggle");
                    document.getElementsByClassName("notorder")[0].classList.toggle("toggle");
                    document.getElementsByClassName("con_mob")[0].classList.toggle("unset");
                    document.getElementsByClassName("con_mob")[0].classList.toggle("categories_mob");
                    if ("categories_mob" === document.getElementsByClassName("cat_mob")[0].classList[1]) {
                        document.getElementsByClassName("cat_mob")[0].classList.toggle("unset");
                        document.getElementsByClassName("cat_mob")[0].classList.toggle("categories_mob");
                        document.getElementsByClassName("yesmenu")[0].classList.toggle("toggle");
                        document.getElementsByClassName("notmenu")[0].classList.toggle("toggle");
                    }
                }
            }}>
                <div className={"yesorder toggle"}><IconOrder/></div>
                <div className={"notorder"}><MenuNonIcon/></div>
                <h5>Order</h5>
            </button>
        )
    }
    return (
        <div className="order">
            <IconOrder/>
            <h5>Order</h5>
        </div>
    )
}

export default Order;