import './menu.css';


const MenuList = () => {
    return (
        <div className="cat_mob unset">
            <h5>Original</h5>
            <h5>Customs</h5>
            <h5>SAIN x KBD</h5>
            <h5>Factory</h5>
        </div>
    )
}

export default MenuList;