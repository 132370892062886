import './menu.css';
import {HandySvg} from 'handy-svg';
import {isMobile} from 'react-device-detect';


export const MenuIcon = () => (
    <HandySvg
        src={"icons/menu.svg"}
        className="icon"
        width="40"
        height="40"
    />
);

export const MenuNonIcon = () => (
    <HandySvg
        src={"icons/menunon.svg"}
        className="icon"
        width="40"
        height="40"
    />
);


const Menu = () => {
    if (isMobile) {
        return (
            <>
                <button type="button" onClick={() => {
                    if (document.getElementsByClassName("yesmenu").length != 0) {
                        document.getElementsByClassName("yesmenu")[0].classList.toggle("toggle");
                        document.getElementsByClassName("notmenu")[0].classList.toggle("toggle");
                        document.getElementsByClassName("cat_mob")[0].classList.toggle("unset");
                        document.getElementsByClassName("cat_mob")[0].classList.toggle("categories_mob");
                        if ("categories_mob" === document.getElementsByClassName("con_mob")[0].classList[1]) {
                            document.getElementsByClassName("con_mob")[0].classList.toggle("unset");
                            document.getElementsByClassName("con_mob")[0].classList.toggle("categories_mob");
                            document.getElementsByClassName("yesorder")[0].classList.toggle("toggle");
                            document.getElementsByClassName("notorder")[0].classList.toggle("toggle");
                        }
                    }
                }}>
                    <div className={"yesmenu toggle"}><MenuIcon/></div>
                    <div className={"notmenu"}><MenuNonIcon/></div>
                </button>
            </>
        )
    }
    return (
        <div className="categories">
            <h3>Original</h3>
            <h3>Customs</h3>
            <h3>SAIN x KBD</h3>
            <h3>Factory</h3>
        </div>
    )
}

export default Menu;