import './contacts.css';
import {HandySvg} from 'handy-svg';


export const IconVK = () => (
    <HandySvg
        src={"icons/vk.svg"}
        className="icon"
        width="40"
        height="40"
    />
);
export const IconTg = () => (
    <HandySvg
        src={"icons/telegram.svg"}
        className="icon"
        width="40"
        height="40"
    />
);
export const IconIn = () => (
    <HandySvg
        src={"icons/insta.svg"}
        className="icon"
        width="40"
        height="40"
    />
);
export const IconYo = () => (
    <HandySvg
        src={"icons/youtube.svg"}
        className="icon"
        width="40"
        height="40"
    />
);

const Contacts = (props) => {
    const {content} = props
    return (
        content.map(content => (
                <div className="contacts" key={content.pk}>
                    <div className="links">
                        <a href={content.telegram}><IconTg/></a>
                        <a href={content.youtube}><IconYo/></a>
                        <a href={content.vk}><IconVK/></a>
                        <a href={content.instagram}><IconIn/></a>
                    </div>
                    <div className="schedule">
                        <h4>10-22 all day for your night</h4>
                    </div>
                </div>
            )
        )
    )
}

export default Contacts;