// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: right;
}
.contacts .links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 16px;
}
.contacts .schedule {
  display: flex;
  align-content: end;
  justify-content: space-around;
  margin-top: auto;
}

`, "",{"version":3,"sources":["webpack://./src/appHeader/contacts.scss","webpack://./src/appHeader/contacts.css"],"names":[],"mappings":"AAMA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,sBAAA;ACJF;ADME;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,8BAAA;EACA,SAbE;ACSN;ADOE;EACE,aAAA;EACA,kBAAA;EACA,6BAAA;EACA,gBAAA;ACLJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n\n$nav-bg: #1C1C1C;\n$safe: 150px;\n$p16: 16px;\n\n.contacts {\n  display: flex;\n  flex-direction: column;\n  gap: calc($p16 / 2);\n  justify-content: right;\n\n  .links {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    justify-content: space-between;\n    gap: $p16;\n  }\n\n  .schedule {\n    display: flex;\n    align-content: end;\n    justify-content: space-around;\n    margin-top: auto;\n  }\n}","@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n.contacts {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: right;\n}\n.contacts .links {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  gap: 16px;\n}\n.contacts .schedule {\n  display: flex;\n  align-content: end;\n  justify-content: space-around;\n  margin-top: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
