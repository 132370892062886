// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  flex-direction: column;
  gap: 128px;
  margin: 0 0 128px 0;
}

.main_mob {
  gap: 0;
  margin: 0 0 64px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

`, "",{"version":3,"sources":["webpack://./src/appHome/home.scss","webpack://./src/appHome/home.css"],"names":[],"mappings":"AAMA;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;ACJF;;ADOA;EACE,MAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;ACJF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n\n$nav-bg: #1C1C1C;\n$safe: 150px;\n$p16: 16px;\n\n.main {\n  display: flex;\n  flex-direction: column;\n  gap: calc($p16 * 8);\n  margin: 0 0 calc($p16 * 8) 0;\n}\n\n.main_mob {\n  gap: 0;\n  margin: 0 0 calc($p16 * 4) 0;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n}","@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n.main {\n  display: flex;\n  flex-direction: column;\n  gap: 128px;\n  margin: 0 0 128px 0;\n}\n\n.main_mob {\n  gap: 0;\n  margin: 0 0 64px 0;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
