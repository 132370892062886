// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: absolute;
  background-color: #1C1C1C;
  padding: 32px 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 300px);
  height: fit-content;
  z-index: 3;
  align-content: end;
}
.footer div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: end;
  align-content: end;
}

.footer_mob {
  padding: 16px 30px;
  flex-direction: column;
  width: calc(100% - 60px);
  gap: 32px;
}
.footer_mob div {
  gap: 4px;
  margin: 0 auto;
}

`, "",{"version":3,"sources":["webpack://./src/appFooter/footer.scss","webpack://./src/appFooter/footer.css"],"names":[],"mappings":"AAOA;EACE,kBAAA;EACA,yBAPO;EAQP,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;ACLF;ADME;EACE,aAAA;EACA,sBAAA;EACA,SAhBE;EAiBF,oBAAA;EACA,kBAAA;ACJJ;;ADQA;EACE,kBAAA;EACA,sBAAA;EACA,wBAAA;EACA,SAAA;ACLF;ADME;EACE,QAAA;EACA,cAAA;ACJJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n\n$nav-bg: #1C1C1C;\n$safe: 150px;\n$safe-mob: 30px;\n$p16: 16px;\n\n.footer {\n  position: absolute;\n  background-color: $nav-bg;\n  padding: calc($p16 * 2) $safe;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: calc(100% - $safe * 2);\n  height: fit-content;\n  z-index: 3;\n  align-content: end;\n  div {\n    display: flex;\n    flex-direction: column;\n    gap: $p16;\n    justify-content: end;\n    align-content: end;\n  }\n}\n\n.footer_mob {\n  padding: $p16 $safe-mob;\n  flex-direction: column;\n  width: calc(100% - $safe-mob * 2);\n  gap: calc($p16 * 2);\n  div {\n    gap: calc($p16 / 4);\n    margin: 0 auto;\n  }\n}","@import url(\"https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap\");\n.footer {\n  position: absolute;\n  background-color: #1C1C1C;\n  padding: 32px 150px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: calc(100% - 300px);\n  height: fit-content;\n  z-index: 3;\n  align-content: end;\n}\n.footer div {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  justify-content: end;\n  align-content: end;\n}\n\n.footer_mob {\n  padding: 16px 30px;\n  flex-direction: column;\n  width: calc(100% - 60px);\n  gap: 32px;\n}\n.footer_mob div {\n  gap: 4px;\n  margin: 0 auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
