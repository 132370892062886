import './category_block.css';
import {isMobile} from "react-device-detect";

const CategoryBlock = (props) => {
    const {type} = props
    let dynamic_class = "category";
    if (type) {
        dynamic_class = "category reverse";
    }
    let mob = dynamic_class + " mob"
    if (isMobile) {
        return (
            <div className={mob}>
                <div className={"right_rect mob"}></div>
                <div className={"left_rect mob"}></div>
                <video id={"video"} className={"mob"} loop="loop" autoPlay="autoplay" muted="muted">
                    <source src={"videos/original.mp4"} type='video/mp4; codecs="avc1.42E030, mp4a.40.2"'/>
                </video>
                <div className={"functional mob"}>
                    <div className={"description mob"}>
                        <h3>SAIN original</h3>
                        <h4>Авто-декор вдохновлённый ритмом ночного заезда и технологическим прогрессом.</h4>
                    </div>
                    <div className={"cat_child mob"}>
                        <h4>Шильды</h4>
                        <h4>Шильды</h4>
                        <h4>Шильды</h4>
                        <hr className={"mob"}/>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={dynamic_class}>
            <div className={"right_rect"}></div>
            <div className={"left_rect"}></div>
            <video id={"video"} width="100%" loop="loop" autoPlay="autoplay" muted="muted">
                <source src={"videos/original.mp4"} type='video/mp4; codecs="avc1.42E030, mp4a.40.2"'/>
            </video>
            <div className={"functional"}>
                <div className={"description"}>
                    <h1>SAIN original</h1>
                    <h2>Авто-декор вдохновлённый ритмом ночного заезда и технологическим прогрессом.</h2>
                </div>
                <div className={"cat_child"}>
                    <h1>Шильды</h1>
                    <h1>Шильды</h1>
                    <h1>Шильды</h1>
                    <hr/>
                </div>
            </div>
        </div>
    )
}

export default CategoryBlock;