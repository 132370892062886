import './footer.css';
import Contacts from "../appHeader/Contacts";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../index";
import {isMobile} from "react-device-detect";

const Footer = (props) => {
    const {data} = props
    const [content, setContent] = useState([])

    useEffect(() => {
        getContent()
    }, [])

    const getContent = (data) => {
        axios.get(API_URL).then(data => setContent(data.data))
    }

    const resetState = () => {
        getContent();
    };

    if (isMobile) {
        return (
            data.map(data => (
                    <div className="footer footer_mob" key={data.pk}>
                        <Contacts content={content} resetState={resetState}/>
                        <div className={"colab"}>
                            <h5>По вопросам сотрудничества:</h5>
                            <h5>{data.mail}</h5>
                            <h5>{data.phone}</h5>
                        </div>
                        <div className={"docs"}>
                            <h5>Публичная оферта</h5>
                            <h5>Политика конфеденциальности</h5>
                        </div>
                    </div>
                )
            )
        )
    }
    return (
        data.map(data => (
                <div className="footer" key={data.pk}>
                    <div className={"colab"}>
                        <h4>По вопросам сотрудничества:</h4>
                        <h4>{data.mail}</h4>
                        <h4>{data.phone}</h4>
                    </div>
                    <div className={"docs"}>
                        <h4>Публичная оферта</h4>
                        <h4>Политика конфеденциальности</h4>
                    </div>
                    <Contacts content={content} resetState={resetState}/>
                </div>
            )
        )
    )
}

export default Footer;